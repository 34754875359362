import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import DashboardHeader from './DashboardHeader';
import { NotificationContainer, NotificationManager } from "react-notifications";

const Resume = () => {
    const [files, setFiles] = useState({});
    const [inputKey, setInputKey] = useState(Date.now());
    const [resumeData, setResumeData] = useState({});
    const [resumeId, setResumeId] = useState({});

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();

    const auth = JSON.parse(localStorage.getItem("auth_user_info"));
    const token = localStorage.getItem('token');
    const applicationId = parseInt(localStorage.getItem('id'), 10);
    const status = localStorage.getItem('application_status');

    const requiredFields = [
        'attested_applicant_cnic_front', 
        'attested_applicant_cnic_back',
        'student_id_card',
        'attested_father_guardian_cnic_front',
        'attested_father_guardian_cnic_back',
        'paid_fee_challan',
        'electricity_bill'
      ];

    useEffect(() => {
        const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
        setIsDisabled(disabledStatuses.includes(status));
    }, [status]);

    useEffect(() => {
        const fetchResumeData = async () => {
            try {
                const campaignResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                const campaignData = await campaignResponse.json();
                const latestCampaign = campaignData.data[0];

                if (!latestCampaign) {
                    console.error("No campaign found");
                    return;
                }

                const resumeResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/documents-uploads?&filters[application][id][$eq]=${applicationId}&populate=*`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                const resumeResult = await resumeResponse.json();
                const resume = resumeResult.data[0];
                console.log("resume.id",resume)
                setResumeId(resume?.id || null);

                if (resume) {
                    console.log("Resumesssss", resume);
                    const existingFiles = {};
                
                    requiredFields.forEach(field => {
                        if (resume[field]?.id) {
                            existingFiles[field] = {
                                file: null, // No new file selected yet
                                path: resume[field]?.url || '', // Ensure path is set correctly
                                name: resume[field].name, // Fetch name from the response
                            };
                        }
                    });
                 console.log("existingFiles",existingFiles)
                    setResumeData(existingFiles);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching resume data:', error);
            }
        };

        fetchResumeData();
    }, [applicationId, token]);

    const handleFileChange = (e, field) => {
        if (e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            if (selectedFile.size <= 5000000 ) {
                setResumeData(prev => ({
                    ...prev,
                    [field]: { ...prev[field], file: selectedFile },  // Store new file
                }));
            } else {
                NotificationManager.error("File must be a PDF and less than 5MB");
    
                // Reset the input field value manually
                e.target.value = ""; // This clears the selected file in the input element
    
                setResumeData(prev => ({
                    ...prev,
                    [field]: { ...prev[field], file: null }, // Clear the file in the state
                }));
            }
        } else {
            NotificationManager.error('No file selected');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage('');
      
        try {
          const formData = new FormData();
      
          // Helper function to fetch existing files as Blob and add them to FormData
          const fetchAndAppendExistingFiles = async () => {
            for (const field of requiredFields) {
              const fileData = resumeData[field];
      
              if (fileData?.file) {
                // If a new file is selected, attach it
                formData.append(field, fileData.file);
              } else if (fileData?.path) {
                // Fetch the existing file from the URL and append it as a Blob
          
              }
            }
          };
      
          // Ensure all required files are accounted for
          const missingFiles = requiredFields.filter(
            (field) => !resumeData[field]?.file && !resumeData[field]?.path
          );
      
          if (missingFiles.length > 0) {
            setErrorMessage(`Please upload: ${missingFiles.join(', ')}`);
            return;
          }
      
          // Fetch and append all files (both new and existing)
          await fetchAndAppendExistingFiles();
      
          // Add application ID to the form data
          formData.append('application_id', applicationId);
      
          if (resumeId) {
            // Update existing resume
            const updateResponse = await fetch(
              `${process.env.REACT_APP_BASE_URL_strapi}/documents-uploads/${resumeId}`,
              {
                method: 'PUT',
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                body: formData,
              }
            );
      
            if (!updateResponse.ok) {
              throw new Error('Failed to update the existing resume');
            }
      
            NotificationManager.success('Resume updated successfully!');
          } else {
            // Create a new resume entry
            const createResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/documents-uploads`, {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            });
      
            if (!createResponse.ok) {
              throw new Error('Failed to upload files');
            }
      
            NotificationManager.success('Files uploaded successfully!');
          }
      
          // Navigate to status page after request succeeds
          navigate('/application/personal-statement');
        } catch (error) {
          console.error('Error uploading files:', error);
          NotificationManager.error('Error uploading files. Please try again.');
        }
      };
      
  

    return (
        <div>
      <div className="mb-5">
            <DashboardHeader />
            </div>
            <div className="container row justify-content-center m-auto mb-5">
                <div className="col-md-10">
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <Navbar />
                        </div>

                        <div className="col-md-8">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-primary" role="status"></div>
                                </div>
                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>Upload Required Documents</h4>
                                            {requiredFields.map(field => (
                                                <div key={field} className="mb-3">
                                                    <label htmlFor={field} className="form-label">
                                                    {field
                                                        .replace(/_/g, ' ') // Replace underscores with spaces
                                                        .replace(/\b\w/g, char => char.toUpperCase())
                                                        .replace(/\bCnic\b/, 'CNIC')} {/* Ensure CNIC is fully uppercase */}

                                                    </label>
                                                    <input
                                                    type="file"
                                                    className="form-control"
                                                    id={field}
                                                    onChange={(e) => handleFileChange(e, field)}
                                                    accept=".pdf,.png,.jpeg,.jpg" 
                                                    disabled={isDisabled}
                                                    key={inputKey + field}
                                                    />
                                                    {resumeData[field]?.path && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-primary mt-2"
                                                        onClick={() => window.open(resumeData[field].path, '_blank')}
                                                    >
                                                        View Uploaded {field.replace(/_/g, ' ')}
                                                    </button>
                                                    )}
                                                </div>
                                                ))}
                                            {errorMessage && <p className="text-danger">{errorMessage}</p>}
                                        </div>

                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary text-white" disabled={isDisabled}>
                                                Save & Next
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};

export default Resume;
