import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import DashboardHeader from './DashboardHeader';
import { NotificationContainer, NotificationManager, } from "react-notifications";

const Acedemicaploads = () => {
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const [inputKey, setInputKey] = useState(Date.now());
    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState(true);
    const [marksheet, setMarkSheetId] = useState(null); // Holds the resume ID if it exists
    const [errorMessage, setErrorMessage] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    const auth = JSON.parse(localStorage.getItem("auth_user_info"));
    const applicantID = auth ? auth.id : null;
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    console.log("token",token)
    const applicationId = parseInt(localStorage.getItem('id'), 10); // Convert to integer

    // useEffect(() => {
    //     console.log(file);
    // }, [file]);

    const status = localStorage.getItem('application_status'); // Assuming the token is stored in localStorage


    useEffect(() => {
     const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
     if (disabledStatuses.includes(status)) {
       setIsDisabled(true);
     } else {
       setIsDisabled(false);
     }
   }, [status]); // Re-evaluate when the status changes


    useEffect(() => {
   

      // Fetch the latest campaign first
fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}` // JWT token of the logged-in user
    }
  })
    .then(response => response.json())
    .then(campaignResponse => {
      setLoading(false);

      const latestCampaign = campaignResponse.data[0]; // Get the latest campaign
  
      if (!latestCampaign) {
        console.error('No campaign found');
        return; // Handle if no campaign exists
      }
  
      console.log('Latest campaign:', latestCampaign);
  
      // Fetch marksheet related to both the user and the latest campaign
      fetch(`${process.env.REACT_APP_BASE_URL_strapi}/academic-uploads?&filters[application][id][$eq]=${applicationId}&populate=marksheet `, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}` // JWT token of the logged-in user
        }
      })
        .then(response => response.json())
        .then(marksheet => {
          console.log('User with marksheet:', marksheet);
  
          const marksheets = marksheet.data; // Extract marksheet
          console.log('User with marksheet:', marksheets);

          if (marksheets.length > 0 && marksheets[0].marksheet) {
            console.log('Resume URL:', marksheets[0].marksheet.url);
          
            setFormData({
              path: marksheets[0].marksheet.url, // URL of the existing resume
              name: marksheets[0].marksheet.name, // Name of the resume file
            });
          
            setMarkSheetId(marksheets[0].id); // Save the resume ID for updating later
          } else {
            console.log('No marksheet related to the latest campaign found for the user');
          }
        })
        .catch(error => {
          console.error('Error fetching user marksheet:', error);
        });
    })
    .catch(error => {
      console.error('Error fetching the latest campaign:', error);
    });
    }, []);

    const handleFileChange = (e) => {
        // console.log("I am working")
        if (e.target.files.length > 0) {
            if (e.target.files[0].size <= 5000000 && e.target.files[0].type === 'application/pdf') {
                setFile(e.target.files[0]);
            } else {
                NotificationManager.error("File must be a PDF and less than 5MB");
                setInputKey(Date.now()); // Reset the file input field
            }
        } else {
            NotificationManager.error('No file selected');
        }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
    
      setErrorMessage('');
    
      // Check if a file is already uploaded and no new file is selected
      if (!file && formData?.path) {
        navigate("/application/personal-statement");
        return;
      }
    
      // If no file is uploaded at all, set an error message and return
      if (!file) {
        setErrorMessage('Please upload Mark Sheet before submitting.');
        return;
      }
    
      try {
        // If a resume already exists (i.e., marksheet is set), delete it first
        if (marksheet) {
          const deleteResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/academic-uploads/${marksheet}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
    
          if (!deleteResponse.ok) {
            throw new Error('Failed to delete existing marksheet');
          }
    
          console.log('Existing marksheet deleted successfully');
        }
    
        // Proceed to upload the new file
        const newFormData = new FormData();
        newFormData.append('file', file);
        newFormData.append('application_id', applicationId);
    
        const createResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/academic-uploads`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: newFormData,
        });
    
        if (!createResponse.ok) {
          throw new Error('Failed to upload marksheet');
        }
    
        const fileData = await createResponse.json();
        console.log('New marksheet uploaded successfully:', fileData);
        localStorage.setItem("marksheet", fileData.id);
    
        // After successful upload, reset the input
        setInputKey(Date.now());
        setFormData({
          path: fileData[0].url,
          name: fileData[0].name,
        });
        setMarkSheetId(fileData[0].id);
    
        // Navigate to next page
    
      } catch (error) {
        console.error('Error handling marksheet upload:', error);
      }
      navigate("/application/finance-details");

    };
    

    return (
        <div>
            {/* Header Start */}
            <div className="mb-5">
                <DashboardHeader />
            </div>

            <div className="container row justify-content-center m-auto mb-5">
                <div className="col-md-10">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-4">
                            <Navbar />
                        </div>

                        <div className="col-sm-12 col-md-8 mb-4">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                                </div>

                            ) : (
                              <form onSubmit={handleSubmit}>
                              <div className="card">
                                  <div className="card-body">
                                      <h4>Academic Uploads</h4>
                                      <div className="row">
                          
                                          <label htmlFor="file">
                                          Upload the latest marksheet <span className="text-danger">*</span>
                                          </label>
                          {!marksheet &&
                                          <div className="input-group mb-3 col-12">
                                              <input
                                                  type="file"
                                                  className="form-control"
                                                  id="file"
                                                  onChange={handleFileChange}
                                                  key={inputKey}
                                                  accept=".pdf"
                                                  disabled={isDisabled}
                                              />
                                            <label className="input-group-text" htmlFor="file">
                                                  {marksheet ? "Update" : "Upload"}
                                              </label>          
                                                            </div>
                                     }
                                              
                                          {marksheet && 
                                                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '5px' }}>
                                                      {file ? (
                                                          // Show file name from selected file
                                                          <span style={{ padding: '10px', color: '#6c757d', flex: '1' }}>{file.name}</span>
                                                        ) : (
                                                          // Show saved file name if no new file is selected
                                                          <span style={{ padding: '10px', color: '#6c757d', flex: '1' }}>{formData?.name || "No file chosen"}</span>
                                                        )}
                                                                                                            
                                                    

                                                        {/* Conditionally display the file input */}
                                                        
                                                          <input
                                                            type="file"
                                                            className="form-control"
                                                            id="file"
                                                            onChange={handleFileChange}
                                                            key={inputKey}
                                                            accept=".pdf"
                                                            disabled={isDisabled}
                                                            style={{ display: 'none' }}

                                                          />
                                                        

                                                        {/* Upload/Update button */}
                                                        <label className="input-group-text" htmlFor="file">
                                                  {marksheet ? "Update" : "Upload"}
                                              </label>   
                                                      </div>
                                         }



                          
                                          {/* Show the name of the already uploaded file, if available */}
                                        
                          
                                          {errorMessage && <p className="text-danger">{errorMessage}</p>}
                          
                                          <div className="input-group mb-3 col-12">
                                              {formData?.path && (
                                                  <button
                                                      type="button"
                                                      className="btn btn-outline-primary m-1"
                                                      onClick={() => window.open(formData.path, '_blank')}
                                                  >
                                                      View
                                                  </button>
                                              )}
                                          </div>
                                      </div>
                                  </div>
                          
                                  <div className="card-footer">
                                      <div className="mc-footer">
                                          <div className="row text-center">
                                              <div className="col-lg-12">
                                                  <button type="submit" className="btn btn-primary text-white m-1"                           disabled={isDisabled}
>
                                                      Save & Next
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </form>
                          
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>

    )

};

export default Acedemicaploads;
