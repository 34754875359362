import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { NotificationContainer, NotificationManager, } from "react-notifications";
import DashboardHeader from "./DashboardHeader";

const AcademicInformation = () => {
  const [rows, setRows] = useState([{
    id: null,
    applicantId: null,
    name: 0,
    fos: null,
    institute: null,
    cgpa:null,
    sgpa:null,
    total_marks:null,
    obtained_marks:null,
    on_going: false,
    yearend: 0,
    yearstart: 0,
    semester:null
  }]);
  const [degrees, setDegrees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("auth_user_info"));
  const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
  const applicationId = parseInt(localStorage.getItem('id'), 10); // Convert to integer
  console.log("token",token)
  const applicantID = auth ? auth.id : null;
  const status = localStorage.getItem('application_status'); // Assuming the token is stored in localStorage

  // Years
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1998 }, (v, i) => ({ id: i + 1, year: 1999 + i }));

  // Fetch degrees


  useEffect(() => {
    const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
    if (disabledStatuses.includes(status)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [status]); // Re-evaluate when the status changes

  // Fetch educational information
  useEffect(() => {

        // Fetch the latest campaign first
fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
  method: 'GET',
  headers: {
    'Authorization': `Bearer ${token}` // JWT token of the logged-in user
  }
})
  .then(response => response.json())
  .then(campaignResponse => {
    setLoading(false);

    const latestCampaign = campaignResponse.data[0]; // Get the latest campaign

    if (!latestCampaign) {
      console.error('No campaign found');
      return; // If no campaign exists, handle appropriately
    }

    console.log('Latest campaign:', latestCampaign);

    // Fetch academic details related to the user and filter by the latest campaign
    fetch(`${process.env.REACT_APP_BASE_URL_strapi}/academic-details?&filters[application][id][$eq]=${applicationId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}` // JWT token of the logged-in user
      }
    })
      .then(response => response.json())
      .then(academic_details => {
        console.log('User with academic-details:', academic_details);

        const academicdetails = academic_details.data; // Extract academic details
        console.log('academic-details related to the user and latest campaign:', academicdetails);
        
        if (academicdetails && academicdetails.length > 0) {
          const transformedRows = academicdetails.map((detail) => ({
            on_going: detail.on_going,
            name: detail.degree,
            yearend: detail.end_year,
            fos: detail.field_of_study,
            institute: detail.institute,
            obtained_marks:detail.obtained_marks,
            semester:detail.semester,
            total_marks:detail.total_marks,
            cgpa:detail.cgpa,
            sgpa:detail.sgpa,
            yearstart: detail.start_year,
            id:detail.id,
            documentId:detail.documentId
          }));
          setRows(transformedRows); // Set the rows with academic details if found
    
        } else {
          console.log('No academic details related to the latest campaign found for the user');
        }
      })
      .catch(error => {
        console.error('Error fetching user and academic details:', error);
      });
  })
  .catch(error => {
    console.error('Error fetching the latest campaign:', error);
  });

  }, []);

  // Add a new row based on coming data fields
  const handleAddMore = () => {
    setRows([...rows, {
      id: null,
      applicantId: applicantID,
      name: 0,
      fos: null,
      institute: null,
      obtained_marks:null,
      semester:null,
      total_marks:null,
      cgpa:null,
      sgpa:null,
      on_going: false,
      yearend: 0,
      yearstart: 0
    }]);
  };

  // Remove a row
  const handleRemove = async (index, ) => {
    console.log("index", index);
    console.log("index", rows[index].id);
console.log("rows",rows)
    // Call the delete API for the Strapi Academic collection
    try {
      const token = localStorage.getItem('token'); // Assuming you are storing the JWT token in localStorage
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL_strapi}/academic-details/${rows[index].id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Provide the token for authentication
          },
        }
      );
  
      if (response.status === 200) {
        // Successfully deleted from the backend, now remove from the frontend
        setRows(rows.filter((row, i) => i !== index));
        console.log("Item successfully deleted from both front-end and backend");
      } else {
        console.error("Failed to delete item from backend", response);
      }
    } catch (error) {
      console.error("Error deleting the item from Strapi", error);
    }
  };

  // // Update input field values
  // const handleInputChange = (index, field, value) => {
  //   setRows(rows.map((row, i) => {
  //     if (i !== index) return row;
  //     return { ...row, [field]: value };
  //   }));
  // };

  // Update input field values
  const handleInputChange = (index, field, value) => {
    setRows(rows.map((row, i) => {
      if (i !== index) return row;
      let newRow = { ...row, [field]: value };
      if (field === 'yearstart') {
        newRow.yearend = newRow.yearend > value ? newRow.yearend : 0;
      } else if (field === 'yearend') {
        newRow.yearstart = newRow.yearstart < value ? newRow.yearstart : 0;
      }
      return newRow;
    }));
  };


  // // Update input field values
  // const handleInputChange = (index, field, value) => {
  //   setRows((prevRows) => {
  //     const updatedRows = prevRows.map((row, i) => {
  //       if (i !== index) return row;
  //       let updatedRow = { ...row, [field]: value };

  //       // Ensure End Year is greater than Start Year
  //       if (field === 'yearstart' && row.yearend && value > row.yearend) {
  //         updatedRow = { ...updatedRow, yearend: value };
  //       } else if (field === 'yearend' && row.yearstart && value < row.yearstart) {
  //         updatedRow = { ...updatedRow, yearstart: value };
  //       }

  //       return updatedRow;
  //     });

  //     return updatedRows;
  //   });
  // };

  // Post Education Request
  // let highestId = Math.max(...rows.map(row => row.id || 0));
  const validateRow = (row) => {
    const rowErrors = {};
    
    if (!row.name) rowErrors.name = 'Degree is required';
    if (!row.institute) rowErrors.institute = 'Institute is required';
    if (!row.fos) rowErrors.fos = 'Field of Study is required';
    if (!row.yearstart) rowErrors.yearstart = 'Start Year is required';
    if (!row.on_going && !row.yearend) rowErrors.yearend = 'End Year is required if not ongoing';
    if (!row.on_going &&  (row.name === "School" || row.name === "College" || row.name === "Doctorate" ) && !row.cgpa) rowErrors.cgpa = 'Cpga  is required if not ongoing';
    if (!row.on_going && (row.name === "School" || row.name === "College" || row.name === "Doctorate" ) && !row.total_marks) rowErrors.total_marks = 'Total Marks  are required if not ongoing';
    if (!row.on_going && (row.name === "School" || row.name === "College" || row.name === "Doctorate" ) && !row.obtained_marks) rowErrors.obtained_marks = 'Obtained Marks  are required if not ongoing';
    if (row.on_going && (row.name === "Bachelors" || row.name === "Masters") && !row.semester) {
      rowErrors.semester = 'Semester Selection Is Required';
    }
    
    

    return rowErrors;
  };
  
  const validateForm = () => {
    const newErrors = rows.map(validateRow);
    setErrors(newErrors);
  
    // Check if there are any errors
    return newErrors.every((rowError) => Object.keys(rowError).length === 0);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("event", event);
    if (!validateForm()) {
      return; // Stop if validation fails
    }
  
    // Prepare academic data
    const academicData = rows.map((row, index) => ({
      degree: row.name,
      field_of_study: row.fos,
      institute: row.institute,
      obtained_marks:row.obtained_marks,
      semester:row.semester,
      total_marks:row.total_marks,
      cgpa:row.cgpa,
      sgpa:row.sgpa,
      on_going: row.on_going,
      end_year: parseInt(row.yearend),
      start_year: parseInt(row.yearstart),
      id: row.id || null, // Check if the row has an ID for updating
      documentid:row.documentId
    }));
  
    try {
      // Iterate through each row and decide whether to create or update
      for (let i = 0; i < academicData.length; i++) {
        const currentRow = academicData[i];
        console.log("currentRow",currentRow)
        if (currentRow.id) {
          const { id,documentid, ...updateData } = currentRow;

          // If the row has an ID, it's an update (PUT request)
          const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/academic-details/${currentRow.documentid}`, {
            method: 'PUT', // Update existing record
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: updateData }) // Send the updated data
            
          });
          
          if (!response.ok) {
            throw new Error('Failed to update academic detail');
          }
  
          const updatedData = await response.json();
          console.log('Updated Data:', updatedData);

        } else {
          delete currentRow.id;

          // If no ID, it's a create (POST request)
          const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/academic-details`, {
            method: 'POST', // Create new record
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: {...currentRow ,applicationId}, }) // Send the new data
          });
  
          if (!response.ok) {
            throw new Error('Failed to create academic detail');
          }
  
          const createdData = await response.json();
          localStorage.setItem("acedemic_detail", createdData.id);
      
          console.log('Created Data:', createdData);
        }
      }
  
      // Navigate after successful submit
      navigate("/application/acedemic-uploads");
  
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  return (
    <div>
      {/* Header Start */}
      <div className="mb-5" >
        <DashboardHeader />
      </div>

      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>

              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="card">
                    <div className="card-body">
                      <h4>Academic Details</h4>
                      <p className='my-3'>Please proceed by providing your most recent academic information.</p>
                      <div className="row">
                        {rows.map((row, index) => (
                          <React.Fragment key={index}>
                            {/* <h5>{degrees.find(degree => degree.id === parseInt(row.name))?.name || "Please proceed by providing your most recent academic information."}</h5> */}
                            {/* Degree */}
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`degreeId${index}`}>Degree <span className="text-danger">*</span></label>
                              <select
                                id={`degreeId${index}`}
                                className="form-control"
                                name="name"
                                value={row.name || 0}
                                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                disabled={isDisabled}

                              >
                                <option key={0} value={0}>Please Select Degree</option>

                                  <option value="College">College</option>
                                  <option value="School">School</option>
                                  <option value= "Bachelors">Bachelors</option>
                                  <option value="Masters">Masters</option>
                                  <option value="Doctorate">Doctorate</option>

                              </select>
                              {errors[index]?.name && <p className="text-danger">{errors[index].name}</p>}

                            </div>
                            {/* Institute */}
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`institute${index}`}>Institute <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                id={`institute${index}`}
                                placeholder="Enter Institute Name"
                                value={row.institute || ''}
                                onChange={(e) => handleInputChange(index, 'institute', e.target.value)}
                                disabled={isDisabled}

                              />
                               {errors[index]?.institute && <p className="text-danger">{errors[index].institute}</p>}

                            </div>

                            {/* Field of Study */}
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`fos${index}`}>Field of Study <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                id={`fos${index}`}
                                placeholder="Enter Field of Study"
                                value={row.fos || ''}
                                onChange={(e) => handleInputChange(index, 'fos', e.target.value)}
                                disabled={isDisabled}

                              />
                                {errors[index]?.fos && <p className="text-danger">{errors[index].fos}</p>}

                            </div>

                            {/* Start Year */}
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`yearStart${index}`}>Start Year <span className="text-danger">*</span></label>
                              <select
                                id={`yearStart${index}`}
                                className="form-control"
                                name="yearstart"
                                value={row.yearstart || 0}
                                onChange={(e) => handleInputChange(index, 'yearstart', e.target.value)}
                                disabled={isDisabled}

                              >
                                <option key={0} value={0}>Select Start Year</option>
                                {years.map((year) => (
                                  <option key={year.id} value={year.year}>
                                    {year.year}
                                  </option>
                                ))}
                              </select>
                              {errors[index]?.yearstart && <p className="text-danger">{errors[index].yearstart}</p>}

                            </div>

                            {/* End Year */}
                            {row.on_going ? null : (
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                               <span>
                                <label htmlFor={`yearEnd${index}`}>End Year</label>
                                <select
                                  id={`yearEnd${index}`}
                                  className="form-control"
                                  name="yearend"
                                  value={row.yearend || 0}
                                  onChange={(e) => handleInputChange(index, 'yearend', e.target.value)}
                                  disabled={isDisabled}

                                >
                                  <option key={0} value={0}>Select End Year</option>
                                  {years.filter(year => year.year >= row.yearstart).map((year) => (
                                    <option key={year.id} value={year.year}>
                                      {year.year}
                                    </option>
                                  ))}
                                </select>
                                {errors[index]?.yearend && <p className="text-danger">{errors[index].yearend}</p>}

                              </span>
                              
                            </div>
                            )}
                               {row.on_going && (row.name === "Bachelors" || row.name === "Masters") && (
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`semester${index}`}>
                                Semester <span className="text-danger">*</span>
                              </label>
                              <select
                                id={`semester${index}`}
                                className="form-control"
                                name="semester"
                                value={row.semester || 0}
                                onChange={(e) => handleInputChange(index, "semester", e.target.value)}
                                disabled={isDisabled}
                              >
                                <option key={0} value={0}>
                                  Please Select Degree
                                </option>
                                <option value={1}>Semester 1</option>
                                <option value={2}>Semester 2</option>
                                <option value={3}>Semester 3</option>
                                <option value={4}>Semester 4</option>
                                <option value={5}>Semester 5</option>
                                <option value={6}>Semester 6</option>
                                <option value={7}>Semester 7</option>
                                <option value={8}>Semester 8</option>
                              </select>
                              {errors[index]?.semester && <p className="text-danger">{errors[index].semester}</p>}
                            </div>
                          )}
                               {row.on_going && (row.name === "Bachelors" || row.name === "Masters") && (
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`sgpa${index}`}>Previous Semester SGPA</label>
                              <input
                                type="text"
                                className="form-control"
                                id={`sgpa${index}`}
                                placeholder="Enter Cgpa"
                                value={row.sgpa || ''}
                                onChange={(e) => handleInputChange(index, 'sgpa', e.target.value)}
                                disabled={isDisabled}

                              />
                               {errors[index]?.sgpa && <p className="text-danger">{errors[index].sgpa}</p>}

                            </div>
                            )}
                               {row.on_going && (row.name === "Bachelors" || row.name === "Masters") && (
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`cgpa${index}`}>CGPA </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`cgpa${index}`}
                                placeholder="Enter Cgpa"
                                value={row.cgpa || ''}
                                onChange={(e) => handleInputChange(index, 'cgpa', e.target.value)}
                                disabled={isDisabled}

                              />
                               {errors[index]?.cgpa && <p className="text-danger">{errors[index].cgpa}</p>}

                            </div>
                            )}
                            <div className="form-group mb-2 col-sm-12 col-md-4">
                              <div className="ul-widget-app__task-list mt-4 pt-2">
                                <label className="checkbox checkbox-primary ">
                                  <input type="checkbox" name="on_going" checked={row.on_going || false} onChange={(e) => handleInputChange(index, 'on_going', e.target.checked)} disabled={isDisabled} />
                                  <strong>
                                    On-going
                                  </strong>
                                  <span className="checkmark"></span>
                                </label>
                                {errors[index]?.on_going && <p className="text-danger">{errors[index].on_going}</p>}

                              </div>
                            
                            </div>
                            {row.on_going ? null : (
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`total_marks${index}`}>Total Marks </label>
                              <input
                                type="number"
                                className="form-control"
                                id={`total_marks${index}`}
                                placeholder="Enter Total Marks "
                                value={row.total_marks || ''}
                                onChange={(e) => handleInputChange(index, 'total_marks', e.target.value)}
                                disabled={isDisabled}

                              />
                               {errors[index]?.total_marks && <p className="text-danger">{errors[index].total_marks}</p>}

                            </div>
                            
                            )}
                                      {row.on_going ? null : (

                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`obtainedmarks${index}`}>Obtained Marks </label>
                              <input
                                type="number"
                                className="form-control"
                                id={`obtained_marks${index}`}
                                placeholder="Enter Obtained Marks "
                                value={row.obtained_marks || ''}
                                onChange={(e) => handleInputChange(index, 'obtained_marks', e.target.value)}
                                disabled={isDisabled}

                              />
                               {errors[index]?.obtained_marks && <p className="text-danger">{errors[index].obtained_marks}</p>}

                            </div>
                              )}
                          
                        
                                {row.on_going ? null : (
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`cgpa${index}`}>Division/Grade/CGPA </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`cgpa${index}`}
                                placeholder="Enter Cgpa"
                                value={row.cgpa || ''}
                                onChange={(e) => handleInputChange(index, 'cgpa', e.target.value)}
                                disabled={isDisabled}

                              />
                               {errors[index]?.cgpa && <p className="text-danger">{errors[index].cgpa}</p>}

                            </div>
                            )}

                            {/* Present Checkbox */}
                            
                            {/* Remove Button */}
                            <div className="form-group mb-4 col-12">
                              {rows.length > 1 && (
                                <button type="button" className="btn btn-danger text-white mt-4" onClick={() => handleRemove(index)}                           disabled={isDisabled}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      <div className="text-left">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handleAddMore}
                          disabled={isDisabled}

                        >
                          Add More
                        </button>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12 ">
                            <button type="submit" className="btn btn-primary text-white m-1"                           disabled={isDisabled}
>
                              Save & Next
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default AcademicInformation;

