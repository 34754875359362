import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager, } from "react-notifications";

import axios from 'axios';

import Navbar from "./Navbar";
import DashboardHeader from "./DashboardHeader";
const PersonalInformation = () => {


  // Province API Service
  // State variables
  const [provinceData, setProvinceData] = useState();
  // const [provinceId, setProvinceId] = useState();
  // const [provinceCityData, setProvinceCityData] = useState(null);
  // const [cityData, setCityData] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState(false);
  const [applicationid, setApplicationId] = useState();
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [profileImage, setProfileImage] = useState(null); // State to hold the selected image
  const [profileImagePreview, setProfileImagePreview] = useState(null); // State to show image preview
  const auth = JSON.parse(localStorage.getItem("auth_user_info"));
  const applicantID = auth ? auth.id : null;
  const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
  const status = localStorage.getItem('application_status'); // Assuming the token is stored in localStorage

 console.log("token",token)
  const navigate = useNavigate();
  // Fetch personal information data and set it to formData state
  useEffect(() => {
    const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
    if (disabledStatuses.includes(status)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [status]); // Re-evaluate when the status changes
  useEffect(() => {
    const fetchData = async () => {

     // Fetch the latest campaign using proper pagination syntax
fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
  method: 'GET',
  headers: {
    'Authorization': `Bearer ${token}` // JWT token of the logged-in user
  }
})
  .then(response => response.json())
  .then(campaignResponse => {
    setLoading(false)
    const latestCampaign = campaignResponse.data[0]; // Get the latest campaign

    if (!latestCampaign) {
      console.error('No campaign found');
      return; // If no campaign exists, handle appropriately
    }

    console.log('Latest campaign:', latestCampaign);
const latestDocumentId = latestCampaign.documentId; // Assuming document_id is a field in the campaign
      console.log('Latest campaign documentId:', latestDocumentId);
    // Fetch applications related to both the user and the latest campaign
    fetch(`${process.env.REACT_APP_BASE_URL_strapi}/users/me?populate[applications][filters][campaign][documentId][$eq]=${latestDocumentId}&populate[applications][populate][1]=profile_picture`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}` // JWT token of the logged-in user
      }
    })
      .then(response => response.json())
      .then(user => {
        console.log('User with filtered applications:', user);

        const applications = user.applications; // Extract the filtered applications
        if (applications.length > 0) {
          setApplication(true);
          setFormData(applications[0]); // Set the form data with the application
          setApplicationId(applications[0].id); // Set the application ID
          console.log("applications[0].profile_picture?.data?.attributes?.url",applications[0])
          if (applications[0].profile_picture?.url) {
            let fullImageUrl = `${applications[0].profile_picture.url}`;
            fullImageUrl = fullImageUrl.replace('/api', '');
            console.log("fullImageUrl",fullImageUrl)
            setProfileImagePreview(fullImageUrl);
          }
          localStorage.setItem("id", applications[0].id);

        } else {
          
          const auth_user_info = JSON.parse(localStorage.getItem('auth_user_info')); // Assuming the token is stored in localStorage
          console.log("auth_user_info",auth_user_info.fname)
          setFormData({
            name: auth_user_info.fname,

            email: auth_user_info.email1
            // Add other fields as necessary
          });
          console.log('No applications related to the latest campaign found for the user');
        }
      })
      .catch(error => {
        console.error('Error fetching user and filtered applications:', error);
      });
  })
  .catch(error => {
    console.error('Error fetching the latest campaign:', error);
  });
    };

    fetchData();

  }, []);

  // Fetch province data when provinceId changes



  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        if (file.size <= 5000000) {
          setProfileImage(file);
          setProfileImagePreview(URL.createObjectURL(file));
        } else {
          NotificationManager.error("File size must be less than 5MB");
        }
      } else {
        NotificationManager.error("Only JPEG and PNG formats are allowed");
      }
    }
  };


  // Save image (simulated example)
  const handleImageSave = async () => {
    if (!profileImage) {
      NotificationManager.error("Please select an image");
      return;
    }

    const formData = new FormData();
    formData.append("files", profileImage);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const data = await response.json();
      NotificationManager.success("Image uploaded successfully");
      console.log("Uploaded Image:", data);
    } catch (error) {
      console.error("Error uploading image:", error);
      NotificationManager.error("Error uploading image");
    }
  };





  // Handle form field changes and update formData state accordingly
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Update form data state
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  
    // Validate the field and dynamically clear errors if the field is valid
    const errorMessage = validateField(name, value);
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage || "", // Clear the error if valid
    }));
  };
  
  
  const validateField = (name, value) => {
    switch (name) {
      case "phone_number":
        if (value && !/^(\+923|03)\d{9}$/.test(value)) 
          return "Phone number is invalid.";
        break;
  
    
  
      case "email":
        if (value && !/\S+@\S+\.\S+/.test(value)) 
          return "Email is invalid.";
        break;
        case "applicant_cnic":
          if (value && !/^\d{13}$/.test(value)) 
            return "CNIC is invalid. It should be a 13-digit number.";
          break;
        
        case "parent_cnic":
          if (value && !/^\d{13}$/.test(value)) 
            return "CNIC is invalid. It should be a 13-digit number.";
          break;
  
      default:
        return null;
    }
  };
  
  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
  
    if (application) {
      // If data is true (or exists), call updateSubmit
      handleSubmit();
    } else {
      // If data is false (or does not exist), call handleSubmit
      handleSubmit();
    }
  };
  // Handle form submission
  const validateForm = () => {
    const newErrors = {}; // Start with a fresh error object
  
    // Utility function to calculate age
    const calculateAge = (dateString) => {
      const birthDate = new Date(dateString);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
  
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };
  
    // Validate each field and store errors
    if (!formData.name) newErrors.name = " name is required";

    if (!formData.father_name) newErrors.father_name = "Father name is required";
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.date_of_birth) {
      newErrors.date_of_birth = "Date of Birth is required";
    } else if (calculateAge(formData.date_of_birth) < 15) {
      newErrors.date_of_birth = "You must be at least 15 years old.";
    }
    if (!formData.applicant_cnic) {
      newErrors.applicant_cnic = "Applicant CNIC is required";
    } else if (formData.applicant_cnic && !/^\d{13}$/.test(formData.applicant_cnic)) {
      newErrors.applicant_cnic = "Applicant CNIC is invalid. It should be a 13-digit number.";
    }
    
    if (!formData.parent_cnic) {
      newErrors.parent_cnic = "Parent CNIC is required";
    } else if (formData.parent_cnic && !/^\d{13}$/.test(formData.parent_cnic)) {
      newErrors.parent_cnic = "Parent CNIC is invalid. It should be a 13-digit number.";
    }
    
    // Check if both CNICs are the same
    if (formData.applicant_cnic && formData.parent_cnic && formData.applicant_cnic === formData.parent_cnic) {
      newErrors.applicant_cnic = "Applicant CNIC and Parent CNIC cannot be the same.";
      newErrors.parent_cnic = "Applicant CNIC and Parent CNIC cannot be the same.";
    }
    
    if (!formData.province) newErrors.province = "Province is required";
    if (!formData.permenent_address) newErrors.permenent_address = "Permenent Address is required";
    if (!formData.temporary_address) newErrors.temporary_address = "Temporary Address is required";
    if (!formData.phone_number) {
      newErrors.phone_number = "phone number is required";
    } else if (!/^(\+923|03)\d{9}$/.test(formData.phone_number)) {
      newErrors.phone_number = "Phone number is invalid.";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!profileImage && !profileImagePreview) newErrors.profile_picture = "Profile picture is required";

  
    // Update the errors state
    setErrors(newErrors);
  
    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };
  
  
  
  const handleSubmit = async (e) => {

    // Validate the form
    if (!validateForm()) {
        return; // If validation fails, stop here
    }

    // FormData object for submission
    const subFormData = new FormData();
    subFormData.append(
        'data',
        JSON.stringify({
            name: formData.name,
            father_name: formData.father_name,
            gaurdian_name: formData.gaurdian_name,
            applicant_cnic: formData.applicant_cnic,
            parent_cnic: formData.parent_cnic,
            gender: formData.gender,
            date_of_birth: formData.date_of_birth,
            province: formData.province,
            permenent_address: formData.permenent_address,
            temporary_address: formData.temporary_address,
            phone_number: formData.phone_number,
            email: formData.email,
            application_status: 'incomplete', // Ensure this is included for creation
        })
    );

    // Append profile_picture if a file is selected
    if (profileImage) {
        subFormData.append('files.profile_picture', profileImage);
    }

    try {
        let response;
        if (application) {
            // Update existing application
            response = await fetch(
                `${process.env.REACT_APP_BASE_URL_strapi}/applications/${applicationid}`,
                {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: subFormData,
                }
            );
        } else {
            // Create a new application
            response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: subFormData,
            });
        }

        if (!response.ok) {
          const errorData = await response.json();
          const errorMsg = errorData?.error?.message || 'Failed to submit form';
           console.log("errorData",errorMsg)
           if(errorMsg == "The provided CNIC already exists for this campaign.")
           NotificationManager.error("The provided CNIC already exists for this campaign.");

           return;


        }

        const data = await response.json();

        if (data?.data?.id) {
            localStorage.setItem('application_id', data.data.id);
            localStorage.setItem('id', data.data.id);

        } else {
            localStorage.setItem('application_id', data.id);
            localStorage.setItem('id', data.data.id);

        }

        console.log('Form submitted successfully:', data);

        // After successful submission, navigate to the next page
        navigate('/application/academic-information');
    } catch (error) {
        console.error('Error submitting form:', error);
    }
};

  
  // const UpdateSubmit = async (applicationid) => {
  //   if (!validateForm()) {
  //     return; // If validation fails, stop here
  //   }
  
  //   const formData = new FormData();
  //  console.log("formData",formData)
  //   // Append all fields to FormData
  //   formData.append("data[permenent_address]", formData.permenent_address || "");
  //   formData.append("data[temporary_address]", formData.temporary_address || "");
  //   formData.append("data[parent_profession]", formData.parent_profession || "");
  //   formData.append("data[secondary_phone_number]", formData.secondary_phone_number || "");
  //   formData.append("data[date_of_birth]", formData.date_of_birth || "");
  //   formData.append("data[disability]", formData.disability || "");
  //   formData.append("data[reason]", formData.reason || "");
  //   formData.append("data[email]", formData.email || "");
  //   formData.append("data[name]", formData.name || "");
  //   formData.append("data[gender]", formData.gender || "");
  //   formData.append("data[landline]", formData.landline || "");
  //   formData.append("data[gaurdian_name]", formData.gaurdian_name || "");
  //   formData.append("data[father_name]", formData.father_name || "");
  //   formData.append("data[nationality]", formData.nationality || "");
  //   formData.append("data[phone_number]", formData.phone_number || "");
  //   formData.append("data[secondary_email]", formData.secondary_email || "");
  //   formData.append("data[applicant_cnic]", formData.applicant_cnic || "");
  //   formData.append("data[parent_cnic]", formData.parent_cnic || "");
  
  //   // Append the profile picture if it exists
  //   if (formData.profile_picture) {
  //     formData.append("files[profile_picture]", formData.profile_picture);
  //   }
  //  console.log("formData",formData)
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${applicationid}`, {
  //       method: "PUT",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: formData,
  //     });
  
  //     if (!response.ok) {
  //       throw new Error("Failed to update form");
  //     }
  
  //     const data = await response.json();
  //     console.log("Application data updated:", data);
  
  //     navigate("/application/academic-information");
  //   } catch (error) {
  //     console.error("Error updating form:", error);
  //   }
  // };
  //console.log(provinceData)

  // Disablities
  // const disabilities = [
  //   { id: 1, name: 'Visual Impairment' },
  //   { id: 2, name: 'Hearing Impairment' },
  //   { id: 3, name: 'Mobility Impairment' },
  //   { id: 4, name: 'Cognitive Impairment' },
  //   { id: 5, name: 'Psychiatric Impairment' },
  //   { id: 6, name: 'No Disablility' },
  // ];

  // Genders
  const genders = [
    { id: 1, name: 'Male' },
    { id: 2, name: 'Female' },
    { id: 3, name: 'Other' },
    { id: 4, name: 'Prefer not to say' }
  ];

  return (

    <div>
      {/* Header Start */}
      <div className="mb-5">
        <DashboardHeader />
      </div>


      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              {/* <MyForm /> */}
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>

              ) : (
                <form onSubmit={handleFormSubmit}>
                  <div className="card">
                    <div className="card-body">
                      <h4>Personal Information</h4>
                      <div className="form-group mb-3">
                        <label>Profile Picture (JPEG or PNG, max 5MB)</label>
                        <div
                          style={{
                            width: "150px",
                            height: "150px",
                            border: "2px dashed #ccc",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                            marginBottom: "10px",
                          }}
                        >
                          {profileImagePreview ? (
                            <img
                              src={profileImagePreview}
                              alt="Profile Preview"
                              style={{ width: "100%", height: "100%", objectFit: "cover" }}
                            />
                          ) : (
                            <span>Click to Upload</span>
                          )}
                        </div>
                        <input
                          type="file"
                          accept="image/jpeg, image/png"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          id="profileImageInput"
                          disabled={isDisabled}

                        />
                        <label
                          htmlFor="profileImageInput"
                          className="btn btn-secondary btn-sm"
                          style={{ cursor: "pointer" }}
                        >
                          Choose File
                        </label>
                      </div>
                      {errors.profile_picture && <p className="text-danger">{errors.profile_picture}</p>}



                      <h5>Basic Information</h5>
                      <div className="form-group mb-3">
                        <label htmlFor="firstName" className="">
                        Name of Applicant <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder="Enter First Name"
                          name="name"
                          value={formData?.name || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                         {errors.name && <p className="text-danger">{errors.name}</p>}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="father_name" className="">
                        Fathers Name  <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="father_name"
                          name="father_name"
                          placeholder="Enter Father Name"
                          value={formData?.father_name || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                         {errors.father_name && <p className="text-danger">{errors.father_name}</p>}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="gaurdian_name" className="">
                        Guardian Name (If father not alive) 
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="gaurdian_name"
                          name="gaurdian_name"
                          placeholder="Enter Gaurdian Name"
                          value={formData?.gaurdian_name || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                       {errors.gaurdian_name && <p className="text-danger">{errors.gaurdian_name}</p>}

                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="applicant_cnic" className="">
                        Applicant CNIC / B FORM <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="applicant_cnic"
                          name="applicant_cnic"
                          placeholder="CNIC or B Form of Applicant"
                          value={formData?.applicant_cnic || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                       {errors.applicant_cnic && <p className="text-danger">{errors.applicant_cnic}</p>}

                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="parent_cnic" className="">
                        Parents/Guardian /CNIC<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="parent_cnic"
                          name="parent_cnic"
                          placeholder="Enter Parent Cnic"
                          value={formData?.parent_cnic || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                       {errors.parent_cnic && <p className="text-danger">{errors.parent_cnic}</p>}

                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="gender" className="">
                          Gender <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="gender"
                          name="gender"
                          placeholder="Select Gender"
                          value={formData?.gender || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        >

                          {(!formData?.gender || formData?.gender === null) && (
                            <option key={0} value={''}>
                              Select Gender
                            </option>
                          )}
                             <option value= "Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                          <option value="PNTS">Prefer Not To Say</option>


                          
                        </select>
                        {errors.gender && <p className="text-danger">{errors.gender}</p>}

                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="dobirth" className="">
                          Date of Birth <span className="text-danger">*</span>
                        </label>
                        <input
                          type="Date"
                          className="form-control"
                          id="dobirth"
                          name="date_of_birth"
                          placeholder="Select Date of Birth"
                          value={formData?.date_of_birth || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                        {errors.date_of_birth && <p className="text-danger">{errors.date_of_birth}</p>}

                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="province">Province<span className="text-danger">*</span></label>
                        <select
                          id="province"
                          className="form-control"
                          name="province"
                          placeholder=" Select Parent Profession"
                          onChange={handleChange}
                          value={formData?.province || 0}
                          disabled={isDisabled}
                        >
                          {(!formData?.province || formData?.province === 0) && (
                            <option key={0} value={0}>
                              Select Province
                            </option>
                          )}
                         <option value="Punjab">Punjab</option>
                        <option value="Sindh">Sindh</option>
                        <option value="Kpk">Khyber Pakhtunkhwa</option>
                        <option value="Balochistan">Balochistan</option>
                        <option value="Islamabad">Islamabad Capital Territory</option>
                        <option value="Gilgit-Baltistan">Gilgit-Baltistan</option>
                        <option value="Ajk">Azad Jammu and Kashmir</option>

                         
                        </select>
                        {errors.province && <p className="text-danger">{errors.province}</p>}

                      </div>

                      {/* <div className="form-group mb-3">
                        <label htmlFor="disabilities" className="">
                          Disability <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="disabilities"
                          name="disability"
                          placeholder="Select Disability"
                          value={formData?.disability || 0}
                          onChange={handleChange}
                          disabled={isDisabled}
                        >
                     

                          {(!formData?.disablity || formData?.disablity === 0) && (
                            <option key={0} value={0}>
                              Select Disability
                            </option>
                          )}
                          <option value= "no">No</option>
                          <option value="yes">Yes</option>
                         
                        </select>
                        {errors.disability && <p className="text-danger">{errors.disability}</p>}
                      </div>
                      {formData?.disability === "yes" && (
                          <div className="form-group mb-3">
                            <label htmlFor="reason" className="">
                            Please Specify <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="reason"
                              name="reason"
                              placeholder="Enter reason"
                              value={formData?.reason || ''}
                              onChange={handleChange}
                              disabled={isDisabled}
                            />
                            {errors.reason && <p className="text-danger">{errors.reason}</p>}
                          </div>
                        )} */}
                      {/* <div className="form-group mb-3">
                        <label htmlFor="nationality" className="">
                          Nationality <span className="text-danger">*</span>
                        </label>
                        <select
                          id="nationality"
                          className="form-control"
                          name="nationality"
                          placeholder=" Select Nationality"
                          value={formData?.nationality || 0}
                          onChange={handleChange}
                          disabled={isDisabled}
                        >
                                              

                          <option key={0} value={0}>
                            Select Nationality
                          </option>

                          <option key={1} value={true}>
                            Pakistan
                          </option>
                          <option key={2} value={false}>
                            Other
                          </option>
                        </select>
                        {errors.nationality && <p className="text-danger">{errors.nationality}</p>}
                      </div> */}

                      <h5>Address</h5>
                      {/* <div className="form-group mb-3">
                        <label htmlFor="birthCityId" className="">
                          Place of Birth <span className="text-danger">*</span>
                        </label>
                        <select
                          id="birthCityId"
                          className="form-control"
                          name="birthCity"
                          placeholder="Select Place of Birth"
                          value={formData?.birthCity || 0}
                          onChange={handleChange}

                        >
                          <option key={0} value={0}>
                            Select Place of Birth
                          </option>
                          {cityData.data &&
                            cityData.data.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div> */}

                      
                      <div className="form-group mb-3">
                        <label htmlFor="ppermenent_address">Permanent Address <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="ppermenent_address"
                          name="permenent_address"
                          placeholder="Enter your Permenent Address"
                          value={formData?.permenent_address || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                          {errors.permenent_address && <p className="text-danger">{errors.permenent_address}</p>}

                    
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="streetAddress" className="">
                        Temporary Address <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="streetAddress"
                          name="temporary_address"
                          placeholder="Enter Temporary Address"
                          value={formData?.temporary_address || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                         {errors.temporary_address && <p className="text-danger">{errors.temporary_address}</p>}

                      </div>

                      <h5>Contact Information</h5>
                      <div className="form-group mb-3">
                        <label htmlFor="mobile1" className="">
                        Phone No <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="mobile1"
                          name="phone_number"
                          placeholder="0300000000000"
                          value={formData?.phone_number || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                        {errors.phone_number && <p className="text-danger">{errors.phone_number}</p>}

                      </div>
                      {/* <div className="form-group mb-3">
                        <label htmlFor="mobile2" className="">
                          Secondary Phone Number
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="mobile2"
                          name="secondary_phone_number"
                          placeholder="+0300000000000"
                          value={formData?.secondary_phone_number || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                          {errors.secondary_phone_number && <p className="text-danger">{errors.secondary_phone_number}</p>}

                      </div> */}
                      {/* <div className="form-group mb-3">
                        <label htmlFor="landline" className="">
                          Landline
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="landline"
                          name="landline"
                          placeholder="+0300000000000"
                          value={formData?.landline || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                        {errors.landline && <p className="text-danger">{errors.landline}</p>}

                      </div> */}
                      <div className="form-group mb-3">
                        <label htmlFor="email1" className="">
                        Email <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="email1"
                          name="email"
                          placeholder="Student@gmail.com"
                          value={formData?.email || ''}
                          onChange={handleChange}
                          disabled={isDisabled}

                        />
                         {errors.email && <p className="text-danger">{errors.email}</p>}

                      </div>
                      {/* <div className="form-group mb-3">
                        <label htmlFor="email2" className="">
                          Secondary Email
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="email2"
                          name="secondary_email"
                          placeholder="Student@gmail.com"
                          value={formData?.secondary_email || ''}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                           {errors.secondary_email && <p className="text-danger">{errors.secondary_email}</p>}

                      </div> */}
                    </div>
                    <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12 ">

                            <button type="submit" className="btn btn-primary text-white m-1"                           disabled={isDisabled}
>
                              Save & Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>

      </div>

      {/* Main End */}
      <NotificationContainer />
    </div>
  );
};

export default PersonalInformation;