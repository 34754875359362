import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardHeader from "./DashboardHeader";
import Navbar from "./Navbar";


const ApplicationInstructions = () => {


    const navigate = useNavigate();
    return (
        <div>
            {/* Header Start */}
            <div className="mb-5">

                <DashboardHeader />
            </div>
            <div className="container row justify-content-center m-auto mb-5">
                <div className="col-md-10">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-4">
                            <Navbar />
                        </div>
                        <div className="col-sm-12 col-md-8 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-body">
                                        <h1>Instructions</h1>
                                        <p>Welcome! You have successfully started an application for <strong>Ramran Talent Scholarship Program 2025 </strong></p>
                                        <p>Thank you for your interest in the program and we look forward to receiving your completed application.</p>
                                        <h5><strong>Application deadline: Thursday, February 15th 2024, at 11:59 PM (PST).</strong></h5>
                                        <p>It is your responsibility to complete and submit your application and all required materials before the deadline. We do not grant extensions or accept late applications. Final decisions will be announced by March 2024. The final applicants will notify by email.</p>
                                        <p><strong>Please complete all sections of this application.</strong> Clicking the "Continue" button on the bottom of each page will save your work and advance you to the next section. You do not need to complete the entire application at one time. You may return to any section and make changes prior to the submission. ctions, so we should revise the submission line. When you are ready to submit, review all the sections and information added to the application and click the "Submit" button to make your submission.</p>
                                        <p>You may contact us at <a href="mailto:info@ramzan.org.pk">info@ramzan.org.pk</a>.</p>
                                        <p>Click the Continue button to begin your application.</p>
                                    </div>

                                </div>
                                <div className="card-footer">
                                    <div className="mc-footer">
                                        <div className="row text-center">
                                            <div className="col-lg-12 ">
                                                <button type="button" className="btn btn-primary m-1 text-white" onClick={() => navigate('/application/personal-information')}>
                                                    Continue
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ApplicationInstructions;

