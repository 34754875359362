import React, { useState } from 'react';

// Search Component
const DashboardSearch = ({ onSearch }) => {
    const [searchFields, setSearchFields] = useState({
        email: '',
        name: '',
        phone_number: '',
        province: '',
    });

    const handleInputChange = (e) => {
        setSearchFields({ ...searchFields, [e.target.name]: e.target.value });
    };

    const handleSearch = () => {
        onSearch(searchFields);
    };

    return (
        <div className="row">
         

            <div className="form-group mb-3 col-sm-12 col-md-6">
                <label htmlFor="email" className="">Email</label>
                <input type="email" className="form-control" id="email" placeholder="Email" name="email" onChange={handleInputChange} />
            </div>

            <div className="form-group mb-3 col-sm-12 col-md-6">
                <label htmlFor="name" className="">Applicant Name</label>
                <input type="text" className="form-control" id="name" placeholder="Applicant Name" name="name" onChange={handleInputChange} />
            </div>

            <div className="form-group mb-3 col-sm-12 col-md-6">
                <label htmlFor="phone_number" className="">Mobile Number</label>
                <input type="tel" className="form-control" id="phone_number" placeholder="Mobile Number" name="phone_number" onChange={handleInputChange} />
            </div>

            <div className="form-group mb-3 col-sm-12 col-md-6">
                <label htmlFor="province" className="">Province</label>
                <input type="text" className="form-control" id="province" placeholder="Province" name="province" onChange={handleInputChange} />
            </div>

           

            <div className="mb-3 col-sm-12 text-right">
                <button onClick={handleSearch} className="btn btn-primary m-1 text-white">Search</button>
            </div>

        </div>
    );
};

export default DashboardSearch