import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { Parser } from 'json2csv';
import FileSaver from 'file-saver';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { NotificationContainer, NotificationManager, } from "react-notifications";

const DashboardTable = ({ event, title, type,status ,gender,province,searchResults,check}) => {
    console.log("event",event)
    console.log("title",title)
    console.log("type",type)
    console.log("status",status)
    console.log("gender",gender)
    console.log("province",province)

    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    console.log("token",token)
    const authUserInfo = JSON.parse(localStorage.getItem('auth_user_info')); // Get the stored JSON string and parse it to an object
    const userId = authUserInfo.id; // Access the 'id' property
    console.log("User ID:", userId);
    const navigate = useNavigate();
    const [applications, setApplications] = useState([]);
    const [realapplications, setRealApplications] = useState([]);

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);  // 0-based index for react-paginate
  
    const pageSize = 1;
    let apiCallURL = null;
    let api = null;

   
  //   if (status) {
  //     api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[application_status][$eq]=${status}&populate[evaluations][populate]=users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage + 1}` ;
  // } else if (gender) {
  //     api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[gender][$eq]=${gender}&populate[evaluations][populate]=users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage + 1}`;
  // } else if (province) {
  //     api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[province][$eq]=${province}&populate[evaluations][populate]=users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage + 1}`;
  // }


  // Fetch Applications and Evaluations information
//   useEffect(() => {
//     const fetchApplicationsWithEvaluations = async () => {
//         try {
//             const response = await fetch(api, {
//                 method: 'GET',
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'application/json',
//                 },
//             });

//             const applicationsData = await response.json();
//             const applications = applicationsData.data;
//                console.log("1122",applications)
//             // Process evaluations and calculate the sum of the relevant fields
//             const updatedApplications = applications?.map(application => {
//                 const evaluations = application.evaluations || [];

//                 const updatedEvaluations = evaluations.map(evaluation => {
//                     const attributes = evaluation;
//                     // Calculate the sum of desired fields
//                     const sum = (attributes.academic_performance || 0) +
//                                 (attributes.financial_need || 0) +
//                                 (attributes.personal_statement || 0);

//                     // Return evaluation with the sum
//                     return {
//                         ...evaluation,
//                         sum: sum, // Add the sum field to the evaluation
//                     };
//                 });

//                 // Update the application with the modified evaluations
//                 return {
//                   ...application,
//                   evaluations: updatedEvaluations, // Update the evaluations while keeping the format unchanged
//                 };
//             });
//          console.log("updatedApplications",updatedApplications)
//          console.log("updatedApplications",updatedApplications[0]?.attributes?.evaluations?.data?.[0]?.sum)
//             setRealApplications(updatedApplications);
//         } catch (error) {
//             console.error('Error fetching applications and evaluations:', error);
//         }
//     };

//     if (api) {
//         fetchApplicationsWithEvaluations();
//     }
// }, [api, token ,currentPage]);

useEffect(() => {
  if (searchResults) {
    console.log("Search Results:", searchResults);
    
    // Update applications with search results
    const applications = searchResults.data;

    // Set total pages based on search results
    setTotalPages(searchResults.meta.pagination.pageCount);
    setCurrentPage(searchResults.meta.pagination.page - 1); // Adjust to 0-based index

    // Process evaluations and calculate the sum of the relevant fields
    const updatedApplications = applications?.map(application => {
      const evaluations = application.evaluations || [];

      const updatedEvaluations = evaluations.map(evaluation => {
          const attributes = evaluation;
          // Calculate the sum of desired fields
          const sum = (attributes.academic_performance || 0) +
                      (attributes.financial_need || 0) +
                      (attributes.personal_statement || 0);

          // Return evaluation with the sum
          return {
              ...evaluation,
              sum: sum, // Add the sum field to the evaluation
          };
      });

      // Update the application with the modified evaluations
      return {
        ...application,
        evaluations: updatedEvaluations, // Update the evaluations while keeping the format unchanged
      };
  });
console.log("updatedApplications",updatedApplications)
console.log("updatedApplications",updatedApplications[0]?.attributes?.evaluations?.data?.[0]?.sum)
  setRealApplications(updatedApplications);
  } else {
    // Build and fetch default data using the existing logic
 

       
    if (status) {
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[application_status][$eq]=${status}&populate[evaluations][populate]=users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage + 1}` ;
  } else if (gender) {
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[gender][$eq]=${gender}&populate[evaluations][populate]=users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage + 1}`;
  } else if (province) {
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[province][$eq]=${province}&populate[evaluations][populate]=users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage + 1}`;
  }

    // Fetch default applications with the existing API logic
    const fetchApplicationsWithEvaluations = async () => {
      try {
        const response = await fetch(api, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const applicationsData = await response.json();
          const applications = applicationsData.data;
          setTotalPages(applicationsData.meta.pagination.pageCount);
          setCurrentPage(applicationsData.meta.pagination.page - 1); // Adjust to 0-based index

          // Process evaluations and calculate the sum of the relevant fields
          const updatedApplications = applications?.map(application => {
                            const evaluations = application.evaluations || [];
            
                            const updatedEvaluations = evaluations.map(evaluation => {
                                const attributes = evaluation;
                                // Calculate the sum of desired fields
                                const sum = (attributes.academic_performance || 0) +
                                            (attributes.financial_need || 0) +
                                            (attributes.personal_statement || 0);
            
                                // Return evaluation with the sum
                                return {
                                    ...evaluation,
                                    sum: sum, // Add the sum field to the evaluation
                                };
                            });
            
                            // Update the application with the modified evaluations
                            return {
                              ...application,
                              evaluations: updatedEvaluations, // Update the evaluations while keeping the format unchanged
                            };
                        });
                     console.log("updatedApplications",updatedApplications)
                     console.log("updatedApplications",updatedApplications[0]?.attributes?.evaluations?.data?.[0]?.sum)
                        setRealApplications(updatedApplications);
        } else {
          console.error("Failed to fetch applications:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching applications:", error);
      }
    };

    fetchApplicationsWithEvaluations();
  }
}, [searchResults, status, gender, province, currentPage, token]);

    // // Fetch Applications information and download as Excel
    // const fetchDataAndDownload = async () => {
    //     try {
    //         const response = await axios.get(
    //             `${process.env.REACT_APP_BASE_URL}/applicant/get-application-list`,
    //             {
    //                 headers: {
    //                     accept: "*/*",
    //                     Event: event,
    //                     SessionKey: "123",
    //                 },
    //                 responseType: 'blob', // Tell axios to get the response as a Blob
    //             }
    //         );
    //         const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    //         FileSaver.saveAs(blob, 'data.xlsx');
    //         setLoading(false);

    //     } catch (error) {
    //         console.error("Error fetching personal information data:", error);
    //         setLoading(false);
    //     }
    // };

    // Fetch Applications information and download as CSV


    // Function to convert JSON data to CSV
    const jsonToCsv = (data) => {
      if (!data || data.length === 0) return '';
      console.log("data test", data);
    
      // Extract main-level headers (excluding 'id')
      const mainHeaders = Object.keys(data[0].attributes).filter(
        (key) => key !== 'id' && key !== 'evaluations' && key !== 'personal_statement'
      );
    
      // Headers for the three fixed evaluations (admin1, admin2, admin3)
      const evaluationHeaders = ['admin1', 'admin2', 'admin3'];
    
      // Headers for personal statement data
      const personalStatementHeaders = ['personal_statement', 'skills', 'social_media'];
    
      // Combine all headers
      const headers = [...mainHeaders, ...evaluationHeaders, ...personalStatementHeaders];
      const csvRows = [headers.join(',')]; // Add headers row
    
      // Process each application entry
      data.forEach((item) => {
        const attributes = item.attributes;
    
        // Extract main-level values
        const mainValues = mainHeaders.map((header) =>
          attributes[header] !== null && attributes[header] !== undefined
            ? attributes[header]
            : ''
        );
    
        // Extract data for the three evaluations (or fill with empty if missing)
        const evaluationValues = [0, 1, 2].map((index) => {
          const evalData = attributes.evaluations?.data[index]?.attributes;
          const Data = attributes.evaluations?.data[index];

          const evaluatorName = evalData?.users_permissions_user?.data?.attributes?.username || '';
          const sum = Data?.sum || '';
    
          return evaluatorName ? `${evaluatorName}: ${sum}` : ''; // Format as "Name: Sum"
        });
    
        // Extract personal statement data
        const personalStatement = attributes.personal_statement?.data?.attributes || {};
        const personalValues = personalStatementHeaders.map((header) =>
          personalStatement[header] !== null && personalStatement[header] !== undefined
            ? personalStatement[header]
            : ''
        );
    
        // Combine all values into a single row
        const row = [...mainValues, ...evaluationValues, ...personalValues];
        csvRows.push(row.join(',')); // Join the values with commas
      });
    
      return csvRows.join('\n'); // Join all rows with newlines
    };
    

    // Fetch Applications information and download as CSV
    const fetchDataAndDownload = async () => {
      setLoading(true);
      
      try {
        // Fetch your real applications data from the server (example)

        console.log("realapplications ====>", realapplications);
    
        // Convert JSON data to CSV
        const csvData = jsonToCsv(realapplications);
    
        // Create Blob from CSV data
        const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });
    
        // Use FileSaver to save the Blob as a CSV file
        FileSaver.saveAs(blob, `applicant_data.csv`);
    
        setLoading(false);
      } catch (error) {
        console.error('Error fetching and downloading data:', error);
        setLoading(false);
      }
    };
        // if (type === "report") {
        //   console.log("1111")
        //     // Convert JSON data to CSV
        //     console.log("realapplications ====>",realapplications)
        //     const csvData = jsonToCsv(realapplications);
        //     // console.log(csvData);

        //     const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });

        //     // Use FileSaver to save the Blob as a CSV file
        //     FileSaver.saveAs(blob, `${title}.csv`);

        //     setLoading(false);
        // } else {
        //   console.log("2222")

        //     try {
        //         const response = await axios.get(
        //             `${process.env.REACT_APP_BASE_URL}/applicant/get-application-export-list`,
        //             {
        //                 headers: {
        //                     accept: "*/*",
        //                     Event: event,
        //                     SessionKey: "123",
        //                 }
        //             }
        //         );
        //         const data = response.data.data; // Extract the data array from the response

        //         // Check if data is null or undefined
        //         if (!data) {
        //             console.log('No data received');
        //             return;
        //         }


        //         // Convert JSON data to CSV
        //         const csvData = jsonToCsv(data);
        //         // console.log(csvData);

        //         // Create a Blob from the CSV data
        //         const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });

        //         // Use FileSaver to save the Blob as a CSV file
        //         FileSaver.saveAs(blob, 'data.csv');

        //         setLoading(false);

        //     } catch (error) {
        //         console.error("Error fetching personal information data:", error);
        //         setLoading(false);
        //     }
        // }


  
    const yes = async (id) => {
    
      if (!id || !token) {
        alert("Application ID or Token missing!");
        return;
      }
    
      let  outgoingData = {
        application_status:'final_stage'
      }           
     try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
          method: 'PUT', 
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Ensure content-type is set to JSON
          },
          body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
        });
    
        if (!response.ok) {
          throw new Error('Failed to submit form');
        }
        NotificationManager.success("Application Moved To Final list");

        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error:', error);
      }


    };
    
  const no = async (id) => {
    
    if (!id || !token) {
      alert("Application ID or Token missing!");
      return;
    }
  
    let  outgoingData = {
      application_status:'rejected'
    }           
   try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
        method: 'PUT', 
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json', // Ensure content-type is set to JSON
        },
        body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit form');
      

      }
      NotificationManager.success("Application Moved To Rejacted");
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error:', error);
    }



};
const recover = async (id) => {
    
  if (!id || !token) {
    alert("Application ID or Token missing!");
    return;
  }

  let  outgoingData = {
    application_status:'new'
  }           
 try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
      method: 'PUT', 
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json', // Ensure content-type is set to JSON
      },
      body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
    });

    if (!response.ok) {
      throw new Error('Failed to submit form');
    }
    NotificationManager.success("Application Moved To new ");

    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error('Error:', error);
  }



};

    // const allKeys = ['id', 'name', 'address', 'education', 'experience', 'nationlity', 'gender', 'email', 'contact', 'status'];
    const allKeys = ['id', 'name', 'email'];


    return (
        <div>
            {loading ? (
                <div className="d-flex justify-content-center">
                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>
            ) : (<div className="card">
                <div className="card-body">
                    <div className="simple-card-title d-flex justify-content-between align-items-center">
                        <h3 className="text-capitalize mb-1">{title}</h3>
                        <button className="btn-icon m-1 text-capitalize btn btn-primary text-white" onClick={fetchDataAndDownload}>
                            <span className="ul-btn__icon">
                                <i className="i-File-Excel"></i>
                            </span>
                            <span className="ul-btn__text">Export Data</span>
                        </button>
                    </div>
                    {realapplications && realapplications.length > 0 && (
  <table className="table table-bordered">
    <thead>
   <tr>
     {/* Other fixed column headers */}
     {['First Name', 'Primary Email', 'Address', 'Primary Phone Number'].map((key) => (
       <th key={key}>{key}</th>
     ))}
     {/* Dynamic headers for evaluations */}
     {['admin', 'admin1'].map((name) => (
       <th key={name}>{name}</th>
     ))}
     <th>Select</th>
   </tr>
 </thead>
    <tbody>
    {realapplications.map((application) => (
   <tr key={application.id}>
   {/* Mapping regular fields */}
   {['name', 'email', 'permenent_address', 'phone_number'].map((key) => (
     <td key={key}>
       {application[key] || 'N/A'}
     </td>
   ))}

   {/* Admin Columns (evaluations data under correct headings) */}
   {['admin', 'admin1'].map((name, index) => {
 const evaluation = application.evaluations?.find(
   (evaluationObj) =>
     evaluationObj.users_permissions_user?.username?.toLowerCase() ===
     name.toLowerCase()
 );
console.log("evaluation",evaluation)
 const username = evaluation?.users_permissions_user?.username || 'N/A';
 console.log("username",username)

 const sum = evaluation?.sum !== undefined && evaluation?.sum !== null ? evaluation.sum : 'N/A';
 const nextStage = evaluation?.next_stage; // Retrieve next_stage
 const backgroundColor = nextStage === true ? 'lightgreen' : nextStage === false ? 'lightcoral' : 'transparent';

 return (
   <td key={name} style={{ backgroundColor }}>
     <strong>{username !== 'N/A' ? `${sum}` : 'N/A'}</strong>
   </td>
 );
   })}

    {/* Select Button */}
    <td>
    {status === 'new' ? (
  <div>
    <button
    type="button"
    className="btn-icon m-1 text-capitalize btn btn-outline-primary"
    onClick={() => navigate(`/applications/view-application/${application.documentId}`)}
  >
    <span className="ul-btn__icon">
      <i className="i-Information"></i>
    </span>
    View
  </button>
  
  </div>
) : status === 'final_stage' ? (
  <div>
    {check === 'finance' ? (
      // Show only the Edit button if check is 'finance'
      <button
        type="button"
        className="btn-icon m-1 text-capitalize btn btn-outline-primary"
        onClick={() => navigate(`/applications/view-application/${application.documentId}`)}
      >
        <span className="ul-btn__icon">
          <i className="i-Edit"></i>
        </span>
        Edit
      </button>
    ) : (
      // Show the View and Reject buttons if check is not 'finance'
      <>
        <button
          type="button"
          className="btn-icon m-1 text-capitalize btn btn-outline-primary"
          onClick={() => navigate(`/applications/view-application/${application.documentId}`)}
        >
          <span className="ul-btn__icon">
            <i className="i-Information"></i>
          </span>
          View
        </button>
        <button
          type="button"
          className="btn-icon m-1 text-capitalize btn btn-outline-primary"
          onClick={() => no(application.id)}
        >
          Reject
        </button>
      </>
    )}
  </div>
): status === 'approved' ? (
  <div>
  <button
      type="button"
      className="btn-icon m-1 text-capitalize btn btn-outline-primary"
      onClick={() => navigate(`/applications/view-application/${application.documentId}`)}
    >
      <span className="ul-btn__icon">
        <i className="i-Information"></i>
      </span>
      View
    </button>
        <button
          type="button"
          className="btn-icon m-1 text-capitalize btn btn-outline-primary"
          onClick={() => yes(application.id)}
        >
          Yes
        </button>
        <button
          type="button"
          className="btn-icon m-1 text-capitalize btn btn-outline-primary"
          onClick={() => no(application.id)}
        >
          No
        </button>
    </div>
  ): (
  <div>
  <button
    type="button"
    className="btn-icon m-1 text-capitalize btn btn-outline-primary"
    onClick={() => navigate(`/applications/view-application/${application.documentId}`)}
  >
    <span className="ul-btn__icon">
      <i className="i-Information"></i>
    </span>
    View
  </button>
   <button
   type="button"
   className="btn-icon m-1 text-capitalize btn btn-outline-primary"
   onClick={() => recover(application.id)}
 >
   Recover
 </button>
 </div>
)}
    </td>
  </tr>
))}

    </tbody>
  </table>
  )}




                </div>
            </div>
            )}
               <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => setCurrentPage(selected)}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
      <NotificationContainer/>
        </div>
    );
};

export default DashboardTable;


// {
//     "data": [
//         {
//             "reference": "DeveDeem IS Demo Direct AT Demo Organization",
//             "address": "Living in Lahore, Faisalabad, Punjab",
//             "name": "Nadeem R Archer",
//             "id": 9,
//             "file": "http://techmac.ovh/rfmse/ReferenceFileUpload/Reference9",
//             "gender": "Male",
//             "disability": "No ",
//             "nationality": "Foreign National",
//             "contact": "+923012345678, +92515347372",
//             "email": "nademo@gmail.com, testnadeem@g.com",
//             "score": 0,
//             "graceScore": 0,
//             "resume": "http://techmac.ovh/rfmse/ReferenceFileUpload/Resume9",
//             "experience": "4",
//             "coverLetter": "Nadeem Statement",
//             "skills": "Nadeem skill",
//             "dob": "2023-12-21",
//             "network": "Linked In",
//             "totalScore": 0,
//             "fundingRequest": "1",
//             "education": "16"
//         },
//         {
//             "reference": "jjh IS yuu AT ytyt",
//             "address": "3Aj905hvflknh klre[oktregpijnm 12245----, Gakuch, Gilgit-Baltistan",
//             "name": "Muhammad  Mustafa",
//             "id": 23,
//             "file": "http://techmac.ovh/rfmse/ReferenceFileUpload/Reference23",
//             "gender": "Male",
//             "disability": "No ",
//             "nationality": "Foreign National",
//             "contact": "+923334383455",
//             "email": "mustafa-amjad@hotmail.com",
//             "score": 0,
//             "graceScore": 0,
//             "resume": "http://techmac.ovh/rfmse/ReferenceFileUpload/Resume23",
//             "experience": "4",
//             "coverLetter": "rtsytjhg hgjhjh hghgj ghh 4565  ytu 455666 5666 -- 00n hfgjh ",
//             "skills": "hjjytuyt ygyut n tyuyui ry t ",
//             "dob": "2222-02-22",
//             "network": "Website",
//             "totalScore": 0,
//             "fundingRequest": null,
//             "education": "16"
//         }
//     ],
//     "status": 200,
//     "message": "New Applications"
// }